import axios from "axios";
import {route} from "ziggy-js";

const addScripts = (data) => {
    if (!data.scripts) {
        return;
    }
    data.scripts.forEach(script => {
        const scriptRegex = /<script.*<\/script>/;
        if (!scriptRegex.test(script)) {
            console.error('Invalid script tag: ' + script);
        }
        let tmp = document.createElement('div');
        tmp.innerHTML = script;
        let tag = document.createElement('script');
        tag.textContent = tmp.querySelector('script').textContent;
        for (const attr of tmp.querySelector('script').attributes) {
            tag.setAttribute(attr.name, attr.value);
        }
        tag.setAttribute('data-cookie-consent', true);

        document.head.appendChild(tag);
    });
}

const addNotice = (data) => {
    if (!data.notice) {
        return;
    }
    let tmp = document.createElement('div');
    tmp.innerHTML = data.notice;
    let cookies = tmp.querySelector('#cookies-policy')
    document.body.appendChild(cookies);
    let tags = tmp.querySelectorAll('[data-cookie-consent]');
    if (!tags.length) {
        return;
    }
    tags.forEach(tag => {
        if (tag.nodeName === 'SCRIPT') {
            const script = document.createElement('script');
            script.textContent = tag.textContent;
            document.body.appendChild(script);
        } else {
            document.body.appendChild(tag);
        }
    });
}

const initCookies = () => {
    cookies.removeAttribute('data-text');
    cookies.classList.remove('cookies--no-js');
    cookies.classList.add('cookies--closing');

    setTimeout(function () {
        cookies.classList.remove('cookies--closing');
    }, 310);
}

const configureCookies = (event) => {
    event.preventDefault();
    axios.post(route('cookieconsent.accept.configuration'), new FormData(event.target))
        .then((response) => addScripts(response.data));
    close();
}

const acceptAllCookies = (event) => {
    event.preventDefault();
    axios.post(route('cookieconsent.accept.all'))
        .then((response) => addScripts(response.data));
    close();
}

const acceptEssentialsCookies = (event) => {
    event.preventDefault();
    axios.post(route('cookieconsent.accept.essentials'))
        .then((response) => addScripts(response.data));
    close();
}

const resetCookies = (event) => {
    event.preventDefault();
    if (document.querySelector('#cookies-policy')) {
        return;
    }
    axios.post(route('cookieconsent.reset'))
        .then((response) => addNotice(response.data));
}

const resize = () => {
    if (window.innerHeight <= cookies.offsetHeight) {
        cookies.querySelector('.cookies__sections').style.maxHeight = '50vh';
    } else {
        cookies.querySelector('.cookies__sections').removeAttribute('style')
    }
}

const toggleExpand = (event, el, hide = true) => {
    event.preventDefault();
    event.target.blur();

    const element = cookies.querySelector(el.getAttribute('href')),
        content = element.firstElementChild,
        height = content.offsetHeight,
        isOpen = element.classList.contains('cookies__expandable--open');

    element.setAttribute('style', 'height:' + (isOpen ? height : 0) + 'px');

    changeText(hide, isOpen, event);

    setTimeout((() => function () {
        element.classList.toggle('cookies__expandable--open');
        element.setAttribute('style', 'height:' + (isOpen ? 0 : height) + 'px');

        setTimeout(function () {
            element.removeAttribute('style');
        }, 310);
    })(cookies), 10);

    hideNotice(hide, isOpen)
}

const changeText = (hide, isOpen, event) => {
    if (hide) return;

    event.target.textContent = isOpen
        ? text.more
        : text.less
}

const hideNotice = (hide, isOpen) => {
    if (!hide) {
        return;
    }

    const container = cookies.querySelector('.cookies__container'),
        containerHeight = container.firstElementChild.offsetHeight;

    container.setAttribute('style', 'height:' + (!isOpen ? containerHeight : 0) + 'px');

    setTimeout(((cookies) => function () {
        cookies.classList.toggle('cookies--show')
        container.classList.toggle('cookies__container--hide');
        container.setAttribute('style', 'height:' + (isOpen ? containerHeight : 0) + 'px');

        setTimeout(function () {
            container.removeAttribute('style');
        }, 320);
    })(cookies), 10);
}

const close = () => {
    cookies.classList.add('cookies--closing');
    setTimeout(((cookies) => {
        return () => {
            let scripts = cookies.parentNode.querySelectorAll('[data-cookie-consent]');

            scripts.forEach(script => {
                script.parentNode.removeChild(script);
            });

            cookies.parentNode.removeChild(cookies);

        }
    })(cookies), 210);
    setTimeout(() => {
        window.location.reload();
    }, 1000);
}

const cookies = document.querySelector('#cookies-policy');
const reset = document.querySelector('.cookiereset');
if (reset) {
    reset.addEventListener('submit', (event) => resetCookies(event))
}

const text = JSON.parse(cookies.getAttribute('data-text'));
if (cookies) {
    const customize = cookies.querySelector('.cookies__btn--customize');
    const details = cookies.querySelectorAll('.cookies__details');
    const acceptAll = cookies.querySelector('.cookiesBtn--accept');
    const acceptEssentials = cookies.querySelector('.cookiesBtn--essentials');
    const configure = cookies.querySelector('.cookies__customize');

    initCookies();

    for (let i = 0; i < details.length; i++) {
        details[i].addEventListener('click', (event) => toggleExpand(event, event.target, false));
    }
    customize.addEventListener('click', (event) => toggleExpand(event, customize));
    acceptAll.addEventListener('submit', (event) => acceptAllCookies(event));
    acceptEssentials.addEventListener('submit', (event) => acceptEssentialsCookies(event));
    configure.addEventListener('submit', (event) => configureCookies(event));
    window.addEventListener('resize', (event) => resize(event));
    document.addEventListener("DOMContentLoaded", () => cookies.classList.remove('!hidden'));
}